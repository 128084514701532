/*

  BASE STYLES / VARIABLES
*/







/*

  SHINE LAYERS

*/


/* :root {
  
    --card-aspect: 0.718;
    --card-radius: 4.55% / 3.5%;
    --card-edge: hsl(47, 100%, 78%);
    --card-back: hsl(205, 100%, 25%);
    --card-glow: hsl(175, 100%, 90%);
  
    --sunpillar-1: hsl(2, 100%, 73%);
    --sunpillar-2: hsl(53, 100%, 69%);
    --sunpillar-3: hsl(93, 100%, 69%);
    --sunpillar-4: hsl(176, 100%, 76%);
    --sunpillar-5: hsl(228, 100%, 74%);
    --sunpillar-6: hsl(283, 100%, 73%);
  
    --sunpillar-clr-1: var(--sunpillar-1);
    --sunpillar-clr-2: var(--sunpillar-2);
    --sunpillar-clr-3: var(--sunpillar-3);
    --sunpillar-clr-4: var(--sunpillar-4);
    --sunpillar-clr-5: var(--sunpillar-5);
    --sunpillar-clr-6: var(--sunpillar-6);
  
  } */
  
  .card {
  
    /* place the card on a new transform layer and
    make sure it has hardward acceleration... we gun'need that! */
    -webkit-transform: translate3d(0px, 0px, 0.01px);
    transform: translate3d(0px, 0px, 0.01px);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    pointer-events: none;
    
    /* make sure the card is above others if it's scaled up */
    z-index: calc(var(--card-scale) * 2);
    
    /* every little helps! */
    will-change: transform, visibility, z-index;
  
  }
  
  .card,
  .card * {
    /* outline is a little trick to anti-alias */
    outline: 1px solid transparent;
  }
  
  .card:not( .interactive ) .card__translater,
  .card:not( .interactive ) .card__rotator,
  .card:not( .interactive ) .card__shine,
  .card:not( .interactive ) .card__glare {
  
    /* if this card is not in a framework, we can add simply
      hover effects when it's not "interactive" */
    transition: all 0.3s ease;
  
  }
  .card:not( .interactive ):hover {
    
    --pointer-x: 25% !important;
    --pointer-y: 10% !important;
    --card-scale: 1.1 !important;
    --card-opacity: 1 !important;
    --translate-x: 0px !important;
    --translate-y: -10px !important;
    --rotate-x: 7deg !important;
    --rotate-y: -19deg !important;
    --background-x: 44% !important;
    --background-y: 36% !important;
    --pointer-from-center: .9 !important;
    --pointer-from-top: .11 !important;
    --pointer-from-left: .25 !important;
    
  }
  
  .card.water       { --card-glow: hsl(192, 97%,  60%); }
  .card.fire        { --card-glow: hsl(9,   81%,  59%); }
  .card.grass       { --card-glow: hsl(96,  81%,  65%); }
  .card.lightning   { --card-glow: hsl(54,  87%,  63%); }
  .card.psychic     { --card-glow: hsl(281, 62%,  58%); }
  .card.fighting    { --card-glow: rgb(145, 90, 39); }
  .card.darkness    { --card-glow: hsl(189, 77%,  27%); }
  .card.metal       { --card-glow: hsl(184, 20%,  70%); }
  .card.dragon      { --card-glow: hsl(51, 60%, 35%); }
  .card.fairy       { --card-glow: hsl(323, 100%, 89%); }
  
  .card,
  .card__rotator {
    aspect-ratio: var(--card-aspect);
    border-radius: var(--card-radius);
  }
  
  .card.interacting {
    z-index: calc(var(--card-scale) * 120);
  }
  
  .card.active .card__translater,
  .card.active .card__rotator {
    /* prevent pinch/double-tap zooms on card */
    touch-action: none;
  }
  
  .card__translater,
  .card__rotator {
    display: grid;
    perspective: 600px;
    will-change: transform, box-shadow;
  
    transform-origin: center;
    -webkit-transform-origin: center;
  }
  
  .card__translater {
    width: auto;
    position: relative;
  
    -webkit-transform: translate3d(var(--translate-x), var(--translate-y), 0.1px) scale(var(--card-scale));
    transform: translate3d(var(--translate-x), var(--translate-y), 0.1px) scale(var(--card-scale));
  }
  
  .card__rotator {
    -webkit-transform: rotateY(var(--rotate-x)) rotateX(var(--rotate-y));
    -webkit-transform-style: preserve-3d;
    transform: rotateY(var(--rotate-x)) rotateX(var(--rotate-y));
    transform-style: preserve-3d;
    
    /* performance */
    pointer-events: auto;
    /* overflow: hidden; <-- this improves perf on mobile, but breaks backface visibility. */
    /* isolation: isolate; <-- this improves perf, but breaks backface visibility on Chrome. */
  }
  
  button.card__rotator {
    /* because the card is a button, there's some default
      browser styles which need to be subdued */
    border: none;
    background: transparent;
    padding: 0;
    -webkit-appearance: none;
    appearance: none;
  }
  
  .card__rotator,
  .card.active .card__rotator:focus {
    transition: box-shadow 0.4s ease, opacity .33s ease-out;
    box-shadow: 
      0 0 3px -1px transparent, 
      0 0 2px 1px transparent, 
      0 0 5px 0px transparent,
      0px 10px 20px -5px black, 
      0 2px 15px -5px black,
      0 0 20px 0px transparent;
  }
  
  .card.active .card__rotator,
  .card__rotator:focus {
    box-shadow: 
      0 0 3px -1px white, 
      0 0 3px 1px var(--card-edge), 
      0 0 12px 2px var(--card-glow), 
      0px 10px 20px -5px black, 
      0 0 40px -30px var(--card-glow), 
      0 0 50px -20px var(--card-glow)
  }
  
  .card__rotator * {
    width: 100%;
    display: grid;
    grid-area: 1/1;
    aspect-ratio: var(--card-aspect);
    border-radius: var(--card-radius);
    image-rendering: optimizeQuality;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    pointer-events: none;
    overflow: hidden;
  }
  
  .card__rotator img {
    height: auto;
    -webkit-transform: translate3d(0px, 0px, 0.01px);
    transform: translate3d(0px, 0px, 0.01px);
  }
  
  .card__back {
    background-color: var(--card-back);
    -webkit-transform: rotateY(180deg) translateZ(1px);
    transform: rotateY(180deg) translateZ(1px);
    backface-visibility: visible;
  }
  
  .card__front,
  .card__front * {
    backface-visibility: hidden;
  }
  
  .card__front {
    opacity: 1;
    transition: opacity 0.33s ease-out;
    -webkit-transform: translate3d(0px, 0px, 0.01px);
    transform: translate3d(0px, 0px, 0.01px);
  }
  
  .loading .card__front {
    opacity: 0;
  }
  
  .loading .card__back {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  
  
  /**
    
  Shine & Glare Effects
  
  **/
  
  .card__shine {
  
    display: grid;
    transform: translateZ(1px);
    overflow: hidden;
    z-index: 3;
  
    background: transparent;
    background-size: cover;
    background-position: center;
  
    filter: brightness(.85) contrast(2.75) saturate(.65);
    mix-blend-mode: color-dodge;
  
    opacity: var(--card-opacity);
  
  }
  
  .card__shine:before,
  .card__shine:after {
  
    --sunpillar-clr-1: var(--sunpillar-5);
    --sunpillar-clr-2: var(--sunpillar-6);
    --sunpillar-clr-3: var(--sunpillar-1);
    --sunpillar-clr-4: var(--sunpillar-2);
    --sunpillar-clr-5: var(--sunpillar-3);
    --sunpillar-clr-6: var(--sunpillar-4);
  
    grid-area: 1/1;
    transform: translateZ(1px);
    border-radius: var(--card-radius);
  
  }
  
  .card__shine:after {
  
    --sunpillar-clr-1: var( --sunpillar-6 );
    --sunpillar-clr-2: var( --sunpillar-1 ); 
    --sunpillar-clr-3: var( --sunpillar-2 );
    --sunpillar-clr-4: var( --sunpillar-3 );
    --sunpillar-clr-5: var( --sunpillar-4 );
    --sunpillar-clr-6: var( --sunpillar-5 );
  
    transform: translateZ(1.2px);
    
  }
  
  .card__glare {
  
    /* make sure the glare doesn't clip */
    transform: translateZ(1.41px);
    overflow: hidden;
  
    background-image: radial-gradient(
      farthest-corner circle at var(--pointer-x) var(--pointer-y),
      hsla(0, 0%, 100%, 0.8) 10%,
      hsla(0, 0%, 100%, 0.65) 20%,
      hsla(0, 0%, 0%, 0.5) 90%
    );
  
    opacity: var(--card-opacity);
    mix-blend-mode: overlay;
    
  }
  
  
  
  
  
  
  /**
  
    Masking Effects
  
  **/
  
  .card.masked .card__shine,
  .card.masked .card__shine:before,
  .card.masked .card__shine:after {
  
    /** masking image for cards which are masked **/
    -webkit-mask-image: var(--mask);
    mask-image: var(--mask);
      -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-position: center center;
    mask-position: center center;
  
  }
  
  .card[data-rarity="rare holo"] .card__glare:after,
  .card[data-rarity="rare holo cosmos"] .card__glare:after,
  .card[data-rarity$="reverse holo"] .card__glare:after {
    clip-path: var(--clip);
  }
  
  .card[data-rarity="rare holo"][data-subtypes^="stage"] .card__glare:after,
  .card[data-rarity="rare holo cosmos"][data-subtypes^="stage"] .card__glare:after,
  .card[data-rarity$="reverse holo"][data-subtypes^="stage"] .card__glare:after {
    clip-path: var(--clip-stage);
  }
  
  .card[data-rarity="rare holo"][data-supertype="trainer"] .card__glare:after,
  .card[data-rarity="rare holo cosmos"][data-supertype="trainer"] .card__glare:after,
  .card[data-rarity$="reverse holo"][data-supertype="trainer"] .card__glare:after {
    clip-path: var(--clip-trainer);
  }