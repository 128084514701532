.admin_menu_wrap::-webkit-scrollbar {
    width: 2px;  /* 스크롤바의 너비 */
}

.admin_menu_wrap::-webkit-scrollbar-thumb {
    height: 5%; /* 스크롤바의 길이 */
    background: #FE7CF3; /* 스크롤바의 색상 */
    border-radius:50px;
}

.admin_menu_wrap::-webkit-scrollbar-track {
    background: rgba(243, 247, 253, 0.37);  /*스크롤바 뒷 배경 색상*/
}

.adminMain_wrap{
    display: flex;
    width: 100%;
    height: 80em;
    background-color: #2e2e3a;
    justify-content: center;
    align-items: center;
    font-family: 'Noto Sans KR', sans-serif;
}

.admin_menu_slot_wrap i{
    font-size: 30px;
    color: #7de4ff;
    margin: 5px;
}

.xi-angle-down-min{
    position: absolute;
    right: 0;
    transition: all 0.1s ease-out;
    transform: rotate(0deg);
}

.xi-angle-down-min.hide{
    transform: rotate(-90deg);
}

.admin_controller{
    justify-content: center;
    display: flex;
    width: 94%;
    height: 92%;
    padding: 12px;
    border-radius: 10px;
    background: #060607;
}

.admin_menubar{
    width: 16%;
    height: 100%;
    color: gainsboro;
    margin-right: 4px;
    min-width: 80px;
}

.admin_canvers{
    width: 84%;
    height: 100%;
    background-color: #4d4d4d;
    border-radius: 10px;
    background-size: 10px 10px; 
    background-image: linear-gradient(90deg, #444 1px, transparent 1px), linear-gradient(#444 1px, transparent 1px); 
    overflow: hidden;
}

.admin_logo{
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin_menu_slot{
    transition: all 0.1s ease-out;
}

.admin_menu_upperWrap{
    height: 86%;
}

.admin_menu_wrap{
    height: 85%;
    display: flex;
    overflow: auto;
    flex-direction: column;
    border-radius: 10px ;
    row-gap: 7px;
}

.admin_menu_slot_wrap{
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 1px 6px;
    border: 1px solid #504949;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    left: -2px;
}

.admin_menu_slot_wrap span{
    width: 100%;
    margin-left: 10px;
    font-weight: bold;
}

.admin_submenu_slot{
    margin: 0px 8px;
    display: flex;
    text-align: center;
    flex-direction: column;
    row-gap: 10px;
    height: auto; /* 기본값으로 설정 */
    max-height: 100%;
    transition: all 0.1s ease-in-out;
    background: #414141;
    transform: translate(-2px,-9px);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.admin_submenu_slot.hide{
    max-height: 0%; /* 숨겨진 상태일 때 height를 0으로 설정하여 사라지도록 함 */
    overflow: hidden; /* overflow: hidden도 함께 적용되어야 함 */
    transition: all 0.1s ease-in-out;
}

.admin_submenu_slot span{
    margin: 10px auto;
    cursor: pointer;
}

.admin_submenu_slot span i{
    font-size: 28px;
    color: #FE7CF3;
}

.admin_homeBtn_wrap i{
    font-size: 40px;
    color: #FE7CF3;
}

.admin_homeBtn_wrap{
    margin: 10px auto 15px;
    font-size: 12px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}

.admin_submenu_slot.dashboard {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.dashboard i.active {
    color: #7de4ff;
}

@media(max-width : 1100px) {
    .admin_menu_slot_wrap>span{
        display: none;
    }

    .admin_homeBtn_wrap span{
        display: none;
    }

    .admin_submenu_slot.dashboard{
        grid-template-columns:1fr 1fr
    }
}
