.orderBox {
    margin: 0 auto;
    width: 80%;
    padding-top: 170px;
    font-family: 'Noto Sans KR', sans-serif;
}

.orderBox h3 {
    color: #7de4ff;
}

.orderBox p {
    color: #FE7CF3;
}

.orderWindow {
    display: flex;
    align-items: baseline;
}

.shippingAddressBox {
    border-top: 1px solid gray;
    width: 500px;
    margin-top: 20PX;
}

.orderproductbox {
    border-top: 2px solid rgb(34, 33, 33);
    border-bottom: 2px solid rgb(34, 33, 33);
    padding-top: 20px;
}

.orderproductbox>ul {
    display: flex;
    justify-content: space-evenly;
}

.orderInformationbox {
    border-top: 1px solid grey;
    width: 500px;
}

.OrderInformationMain {
    padding-right: 20px;
    padding-left: 250px;
}

.paymentMethodMain>button {
    border: none;
    background: #fff;
}

.kakaopay {
    width: 100px;
}