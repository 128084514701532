
@font-face {
   font-family: 'TmoneyRoundWindExtraBold';
   src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/TmoneyRoundWindExtraBold.woff') format('woff');
   font-weight: normal;
   font-style: normal;
 }

.event1_wrap{
   width: 100%;
   height: 760px;
   background: white;
   text-align: center;
   margin-top: 150px;
   position: relative;
   overflow: hidden;
}

.custom-rectangle{
   position: absolute;
   right: -650px;
   border-right: 1100px solid transparent;
   border-top: 150px solid transparent;
   border-bottom: 350px solid transparent;
   top: 290px;
   background: #fff;
   transform: rotate(140deg);
   overflow: visible;
 }

 @keyframes shaIn {
   0%{
      transform: translateX(0%);
   }
   100%{
      transform: translateX(-100%);
   }
 }

 .event_Pcircle{
   width: 300px;
   height: 300px;
   background: hsl(326, 35%, 80%);
   border-radius: 50%;
   position: absolute;
   left: 630px;
   top: 150px;
   display: flex;
   align-items: center;
   justify-content: center;
   transform: rotate(-140deg);
 }

 .event_pImg{
   width: 200px;
 }
 
 .pImg_container {
  position: relative;
  cursor: pointer;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(115deg,
  transparent 40%,
  rgba(255, 187, 246, 0.8) 45%,
  rgba(255, 255, 255, 0.6) 50%,
  transparent 54%);
  filter: brightness(1.1) opacity(0.8);
  mix-blend-mode: color-dodge;
  background-size: 150% 150%;
  background-position: 100%;
}

.card__glare {
  
  /* make sure the glare doesn't clip */
  transform: translateZ(1.41px);
  overflow: hidden;

  background-image: radial-gradient(
    farthest-corner circle at var(--pointer-x) var(--pointer-y),
    hsla(0, 0%, 100%, 0.8) 10%,
    hsla(0, 0%, 100%, 0.65) 20%,
    hsla(0, 0%, 0%, 0.5) 90%
  );

  opacity: var(--card-opacity);
  mix-blend-mode: overlay;
  
}


.event_Pcircle::before, .event_Pcircle::after
{
position: absolute;
content : "";
top: 2%;
left: 2%;
height:80%;
width:80%;
border-radius:50%;
opacity:0.5;
filter: blur(40px);
}


.event_Pcircle::after
{
background-color:#00ffff;
z-index: -1;
animation : backglow2 7s ease infinite;
}

.event_Pcircle::before
{
background-color:#ff0072;
z-index: -2;
animation : backglow1 7s ease infinite;
}

.Layer_1{
  pointer-events: none;
  display: block; 
  position:absolute;
  width: 300px; 
  height: 300px; 
  z-index:1;
  left: -50px; 
  top:60px;
}

.pImg_container>svg>path{
  fill: none; 
  stroke: #000; 
  stroke-miterlimit: 10; 
  stroke-width: 6px;
  opacity: 0;
}

.pImg_container>svg>path.active {
  stroke-dasharray: 2643.155029296875px;
  stroke-dashoffset: 2643.155029296875px;
  opacity: 1;
  animation: LinAnimation 1s ease-in-out forwards;
}

@keyframes LinAnimation{
  0% {
    stroke-dashoffset: 2643.155029296875px;
  }
  100%{
    stroke-dashoffset: 0px;
  }
}




@keyframes backglow1
{

0%
{
transform: translate(10%, 10%) scale(1);
opacity:0.5;
}

25%
{
transform: translate(-10%, 10%) scale(0.8);
opacity:0.5;
}

50%
{
transform: translate(-10%, -10%) scale(1.2);
opacity:0.8;
}

75%
{
transform: translate(10%, -10%) scale(1.1);
opacity:0.2;
}

100%
{
transform: translate(10%, 10%) scale(1);
opacity:0.5;
}
}





@keyframes backglow2
{

0%
{
transform: translate(-10%, -10%) scale(1);
opacity:0.5;
}

25%
{
transform: translate(10%, -10%) scale(0.8);
opacity:0.5;
}

50%
{
transform: translate(10%, 10%) scale(1.2);
opacity:0.8;
}

75%
{
transform: translate(-10%, 10%) scale(1.1);
opacity:0.2;
}

100%
{
transform: translate(-10%, -10%) scale(1);
opacity:0.5;
}


}