.withdrawal-container {
  width: 300px;
  height: 280px;
  overflow-y: auto;
  border: 1px solid whitesmoke;
  border-radius: 40px;
  background-color: whitesmoke;
  padding: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 125px;
}

.withdrawal-check {
  width: 350px;
  height: 15px;
  border: 1px solid rgb(255, 242, 243);
  border-radius: 20px;
  background-color: rgb(255, 242, 243);
  padding: 20px;
  margin: 50px 125px;
  color: red;
  font-size: 23px;
  text-align: center;
  text-decoration: underline;
  line-height: 100%;
}

.withdrawal-recheck {
  line-height: 100%;
  width: 350px;
  height: 15px;
  font-size: 20px;
  border: 1px solid whitesmoke;
  border-radius: 20px;
  background-color: whitesmoke;
  padding: 20px;
  margin: 50px 125px;
  text-align: center;
}

.withdrawalinput {
  display: inline-block;
  border: 1px solid whitesmoke;
  border-radius: 20px;
  background-color: whitesmoke;
  outline: none;
  font-size: 18px;
  text-align: center;
  width: 300px;
}

.withdrawal-completebtn {
  font-family: 'Noto Sans KR', sans-serif;
  width: 560px;
  margin-left: 50px;
  height: 60px;
  font-size: 23px;
  border: 1px solid whitesmoke;
  border-radius: 20px;
  background-color: whitesmoke;
}

.withdrawal-content {
  margin-right: 20px;
}

.withdrawal-container::-webkit-scrollbar {
  width: 10px;

}

.withdrawal-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.withdrawal-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.withdrawal-header {
  font-size: 25px;
  font-weight: bold;
}