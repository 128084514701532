.modal_wrap{
   display: inline-block;
   /* border: 3px solid #7de4ff; */
   border-top-left-radius: 10px;
   border-radius: 15px;
   overflow: hidden;
   background-color: rgba(138, 204, 212, 0.411) ;
   z-index: 1;
   width: auto;
   height: auto;
   /* opacity: 0.7; */
   margin: 5px;
   position: absolute;
}

.modal_header{
   display: flex;
   justify-content: right;
   align-items: center;
   width: 100%;
   height: 17px;
   color: #7de4ff;
   font-weight: bold;
   border-top-left-radius: 10px;
   border-top-right-radius: 10px;
   margin: 0 auto;
   background: #7de5ff77;
   cursor: grab;
   z-index: 2;
}

.modal_wrap .xi-signal{
   position: absolute;
   color: #7de4ff;
   right: 4px;
   bottom: 4px;
}