@font-face {
  font-family: 'TmoneyRoundWindExtraBold';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/TmoneyRoundWindExtraBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
.notosanskr * { 
 font-family: 'Noto Sans KR', sans-serif;
}

html{
  width: 100%;
  height: 100%;
}

body{
  width: 100%;
  height: 100%;
}

a{
  text-decoration: none;
}

ul{
  list-style: none;
  padding: 0;
  margin: 0;
}

.logo-container{
  font-family: 'TmoneyRoundWindExtraBold';
  height: 10vh;
  display: flex;
  justify-content: space-around;
}

html{
  overflow-y: scroll;
}

a:-webkit-any-link{
  color: #7de4ff;
  cursor: auto;
}

i {
  cursor: pointer;
}

.TBBtn_wrap{
  position: fixed;
  bottom: 40px;
  right: 40px;
  transition: opacity 0.3s;
}

.topBtn, .bottomBtn{
  font-family: 'TmoneyRoundWindExtraBold';
  width: 40px;
  height: 40px;
  background-color: #FE7CF3;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin: 15px 0;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.3s;
}

.bottomBtn>i{
  left: 13px;
  bottom: 25px;
}

.TBBtn_wrap .topBtn.visible {
  opacity: 1;
}

.TBBtn_wrap .bottomBtn.visible {
  opacity: 1;
}

.topBtn:hover {
  opacity: 1; /* 호버 시 오퍼시티 1로 변경 */
}

.bottomBtn:hover {
  opacity: 1; /* 호버 시 오퍼시티 1로 변경 */
}

.hidden{
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}