.userlist_wrap,.loginuser_wrap,.adminlist_wrap{
    width: 100%;
    height: 100%;
}

.userlist_container{
    border: 1px solid #b6b6b6;
    border-radius: 5px;
    margin: 10px 10px 0px;
    background: rgba(148, 147, 147, 0.53);
    text-align: center;
    color: white;
}

.userlist_header{
    /* display: inline-block; */
    color: gainsboro;
    font-size: 30px;
    font-weight: bold;
    height: 130px;
    display: flex;
    align-items: center;
}

.userlist_header span{
    margin-left: 70px;
}

.userlist_statistical{
    display: flex;
    width: 90%;
    margin: 10px auto;
    justify-content: space-around;
}

.userlist_statistical div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 100px;
    border-radius: 20px;
    margin: 20px;
    flex-direction: column;
}

.userlist_statistical div span {
    font-size: 20px;
    font-weight: bold;
    margin: 5px;
}

.userlist_statistical div:nth-child(1){
    border: 1px solid #7de4ff;
    background: #7de5ff2c;
    width: 25%;
    margin-top: 30px;
    border-radius: 5px;
    padding-left: 5px;
    /* background-color: #003a528c; */
    color: gainsboro
}

.userlist_statistical div:nth-child(2){
    border: 1px solid #FE7CF3;
    background: #fe7cf327;
    width: 25%;
    margin-top: 30px;
    padding-left: 5px;
    border-radius: 5px;
    /* border: 3px solid #70a074; */
    /* background-color: #0053078c; */
    color: gainsboro
}

.userlist_statistical div:nth-child(3){
    border: 1px solid #6f50f7;
    background: #947cfe43;
    width: 25%;
    margin-top: 30px;
    padding-left: 5px;
    border-radius: 5px;
    /* border: 3px solid #c70404; */
    /* background-color: #7e00008c; */
    color: gainsboro
}

.status.signed{
    background: #cc5ec39a;
    color: #ffffffe3;
 }
 
 .status.unsigned{
    background: #5541adbe;
    color: #ffffff93;
 }
 
 .status.admin{
    background: #66e05b97;
    color: #ffffffe3;
 }