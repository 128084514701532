.statelistBox::-webkit-scrollbar {
    width: 2px;
    /* 스크롤바의 너비 */
}

.statelistBox::-webkit-scrollbar-thumb {
    height: 5%;
    /* 스크롤바의 길이 */
    background: #FE7CF3;
    /* 스크롤바의 색상 */
    border-radius: 50px;
}

.statelistBox::-webkit-scrollbar-track {
    background: rgba(243, 247, 253, 0.37);
    /*스크롤바 뒷 배경 색상*/
    margin-top: 20px;
}

/* 전체 (div) */
.statelistMain {
    width: 100%;
    display: flex;
}

/* 왼쪽(div) + 오른쪽(div)  배치*/
.statelistBox {
    width: 20%;
    max-height: 1170px;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    overflow: auto;
    align-items: start;
}

.statei {
    width: 96%;
    margin-top: 10px;
}

.seateState {
    border: 1px solid #b6b6b6;
    border-radius: 5px;
    margin-top: 10px;
    background: rgba(148, 147, 147, 0.53);
    text-align: center;
    color: white;
    cursor: pointer;
}

.seateState span:nth-child(1) {
    font-size: 10px;
}

.seateState>h3 {
    margin-top: -5px;
}

.seateState p {
    font-size: 11px;
}

.seateState>p {
    color: #414141;
}


.seateShipping {
    border: 1px solid rgba(255, 0, 0, 0.495);
    background: rgba(255, 0, 0, 0.495);
    border-radius: 5px;
    width: 120px;
    margin: 10px 5px;
    height: 20px;
    line-height: 15px;
}

.stateDelivery {
    width: 80%;
    border: 1px solid #b6b6b6;
    border-radius: 5px;
    margin-top: 20px;
    background: rgba(148, 147, 147, 0.53);
    margin-left: 10px;
    margin-right: 10px;
    color: white;
}

.stateDelivery h3 {
    padding-left: 20px;
}

.stateProgress {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12%;
    margin: 0 auto;
    width: 100%;
    padding-top: 20px;
}

.stateps {
    padding-top: 20px;
}

.stateProgressstatusarrow {
    display: flex;
    justify-content: space-around;
}

.stateDelivery>div:nth-child(2) {
    border-top: 1px solid #fff;
}

.stateProgress>p {
    width: 25%;
}

.stateProgress>p.refund {
    width: 84%;
    border-color: red;
}

.stateProgress>p.refundrequest {
    width: 84%;
}

.stateProgresswrap {
    display: flex;
    width: 100%;
    padding: 0 50px
}

.stepByStep {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    padding: 0 1%;
}

.stage>p {
    font-size: 12px;
    color: #414141;
    text-align: center;
}

.stepByStepStage {
    display: flex;
    justify-content: space-between;
    width: 86%;
    padding: 0 12%;
}

.informationDiv {
    display: flex;
    justify-content: space-around;
}


.stateInformation {
    border: 1px solid #7de4ff;
    background: #7de5ff2c;
    width: 25%;
    margin-top: 30px;
    border-radius: 5px;
    padding-left: 5px;
}

.stateInformation>p {
    font-size: 12px;
    color: #414141;
}

.stateInformation1 {
    border: 1px solid #FE7CF3;
    background: #fe7cf327;
    width: 25%;
    margin-top: 30px;
    padding-left: 5px;
    border-radius: 5px;
}

.stateInformation1>p {
    font-size: 12px;
    color: #414141;
}

.stateInformation2 {
    border: 1px solid #6f50f7;
    background: #947cfe43;
    width: 25%;
    margin-top: 30px;
    padding-left: 5px;
    border-radius: 5px;
}

.stateInformation2>p {
    font-size: 12px;
    color: #414141;
}

/* 반응 */
@media(max-width : 1300px) {
    .statelistBox {
        display: flex;
        flex-direction: column;

    }

    .stateDelivery {
        width: 90%;
    }

    .statei {
        width: 90%;
        margin-left: 2%;
    }

    .stage {
        width: 40px;
        padding-left: 10px;
        margin: 5px 5px 5px 5px;
        font-size: 13px;
    }
}

.map-container {
    margin: 50px auto;
    width: 60%;
    margin-left: 100px;

}

.worldmapPath,
.worldping {
    margin: 0 auto;
    fill: lightgray;
    stroke: white;
}

.svgworldmap {
    width: 700px;
    height: 500px;
}

.pathkorea {
    fill: blue;

}

.positionping {
    fill: black;

}

.statusarrow {
    width: 30px;
    line-height: 30px;
    border-radius: 100%;
    margin: 5px 5px 5px 5px;
    text-align: center;
    border: 1px solid gray;
    background: gray;
    color: black;
}

.statusarrow.paid {
    background: #00fe19;
    border-color: #00fe19;
}

.statusarrow.readyforship {
    background: #00fe19;
    border-color: #00fe19;
}

.statusarrow.shipping {
    background: #00fe19;
    border-color: #00fe19;
}

.statusarrow.deliveried {
    background: #00fe19;
    border-color: #00fe19;
}

.statusRefundarrow {
    width: 30px;
    line-height: 30px;
    border-radius: 100%;
    margin: 5px 5px 5px 5px;
    text-align: center;
    border: 1px solid gray;
    background: gray;
    color: black;
}

.statusRefundprocess {
    width: 90px;
    line-height: 30px;
    border-radius: 100%;
    margin: 5px 5px 5px 5px;
    text-align: center;
    border: 1px solid gray;
    background: gray;
    color: black;
}

.statusRefundarrow.refund {
    background: red;
    border-color: red;
}

.statusRefundarrow.refundrequest {
    background: red;
    border-color: red;
}

.xi-check.delivery {
    color: white;
}