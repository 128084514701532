.CartListFromDiv {
    margin: 0 auto;
    width: 100%;
    padding-bottom: 3.79%;
    padding-top: 5%;
}

@media(max-width : 1330px) {
    .CartListFromDiv {
        width: 90%;
    }
}

.popkornBtnbox {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
}


.CartListFromitem {
    border-top: 1px solid rgb(194, 193, 193);
    border-bottom: 1px solid rgb(194, 193, 193);
    display: block;
    padding-bottom: 0.76%;
}

.noCartListFrom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5%;
    padding-bottom: 5%;

}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.52%;
}

.CartListFromDiv button {
    margin-left: auto;
}

.cartListMain>img {
    width: 10%;
    height: auto;
}

.cartListMain {
    padding-top: 0.76%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1.5%;
    padding-right: 1.5%;
}

.cartListMain>.productnameclss {
    width: 45%;
    display: flex;
    flex-direction: column;
}

.productnameclss>span:nth-child(2) {
    font-size: 12px;
    color: gray;
}

.noCartListFrom>.xi-cart {
    font-size: 50px;
    color: #fe7cf3;
}