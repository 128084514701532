@font-face {
   font-family: 'TmoneyRoundWindExtraBold';
   src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/TmoneyRoundWindExtraBold.woff') format('woff');
   font-weight: normal;
   font-style: normal;
 }

.celeblistpage_wrap{
   width: 100%;
   height: 100%;
   margin-top: 180px;
   margin-bottom: 100px;
}

.meet_new_celeb_span{
   display: inline-block;
   font-size: 30px;
   font-family: 'TmoneyRoundWindExtraBold';
   color: #7de3ff;
   margin: 50px 160px 20px;
}

.meet_new_celeb_span i{
   color: #FE7CF3;
   font-weight: bold;
   cursor: auto;
}

.celebs_list_container{
   margin: 0 auto;
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
   row-gap: 30px;
   column-gap: 30px;
   width: 80vw;
   background: #f7f7f7f7;
   height: auto;
   padding: 40px;
   border-radius: 40px
}