.slick-slider {
   overflow: hidden;
   position: relative;
}

.slick-dots {
   top: 410px;
}

.slick-dots li.slick-active button:before {
   font-size: 10px;
}

.slick-dots li button:before {
   color: #FE7CF3;
}

.event2_wrap {
   position: relative;
   background: #fff;
   margin: 100px auto;
   /* overflow: hidden; */
}

.slick-arrow.slick-next {
   position: absolute;
   right: 15px;
   top: 154.2px;
}

.slick-arrow.slick-prev {
   position: absolute;
   left: 15px;
   top: 154.2px;
   z-index: 1;
}

.event2_slide_container {
   height: 400px;
}

.event2_IMG {
   width: 33vw;
}

.event2_span_wrap {
   width: 100%;
   height: 100%;
   background: #000;
}

.event2_span1 {
   display: inline-block;
   padding: 15px 0 0;
   font-size: 20px;
   color: #323232;
   font-weight: bold;
}

.event2_span2 {
   font-weight: bold;
   display: block;
   padding: 5px;
   color: #656565;
   font-size: 16px;
   text-align: right;
}

.event_modal_container {
   position: fixed;
   /* position: relative; */
   top: 50%; /* 화면 세로 중앙 */
   left: 50%; /* 화면 가로 중앙 */
   transform: translate(-50%, -50%);
   width: 1000px; /* 모달 너비 */
   height: 900px; /* 모달 높이 */
   z-index: 10;
   /* border: solid 1px; */
   /* background-color: rgba(128, 128, 128, 0.5); 회색 배경에 50% 오퍼시티 */
   background-color: white;
   border-radius: 10px;
   overflow-y: auto;
}

.event_modal_content {
   /* position: relative; */

   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   /* max-height: 1000px; */
   border-radius: 10px;

}

.event_modal_btn_wrap {
   position: absolute;
   width: 50px;
   height: 30px;
   
   bottom: 10px;
   right: 10px;
   
}

.event_modal_close_btn {
   
   border: none;
   border-radius: 10px;
   
   
}