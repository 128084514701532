.pagination_wrap{
    width: 100%;
    height: 200px;
    margin: 60px auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.paging_pagenum_div{
    font-size: 20px;
    font-weight: bold;
    display: flex;
    color: #7de4ff;
}

.paging_pagenum_div span{
    cursor: pointer;
}

.currpagenum{
    cursor: pointer;
    color: #FE7CF3;
}

.pagenum_round{
    margin: 0 17px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: 4px 4px 3px gray;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 30px;
    cursor: pointer;
}
.pagenum_round.arrow{
    margin: 0 17px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: 4px 4px 3px gray;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 30px;
    cursor: pointer;
    color: #7de4ff;
}

.pagenum_round.arrowbtn{
    margin: 0 17px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: 4px 4px 3px gray;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 30px;
    cursor: pointer;
    color: #7de4ff;
}

.pagenum_round.nextprev{
    font-family: 'TmoneyRoundWindExtraBold';
    margin: 0 5px;
    width: 50px;
    height: 32px;
    border-radius: 20px;
    box-shadow: 4px 4px 3px gray;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 32px;
    cursor: pointer;
    color: #7de4ff;
}