.external-tooltip {
   position: absolute;
   z-index: 9999;
   background-color: #555;
   color: #fff;
   border-radius: 5px;
   pointer-events: none; /* 마우스 이벤트를 툴팁에 전달하지 않음 */
}

.listform_container {
   border: 1px solid #b6b6b6;
   border-radius: 5px;
   margin: 0px 10px;
   padding: 1px 0px 0px;
   background: rgba(148, 147, 147, 0.53);
   /* text-align: center; */
   color: white;
   border-top: 0;
   border-top-left-radius: 0;
   border-top-right-radius: 0;
}

.checkboxcontainer{
   text-align: center;
}

.listform_wrap {
   width: 90%;
   max-height: 750px;
   margin: 10px auto;
   overflow: auto;
   border-radius: 20px;
}

.listform_wrap::-webkit-scrollbar {
   width: 6px;
   /* 스크롤바의 너비 */
   height: 10px;
}

.listform_wrap::-webkit-scrollbar-thumb {
   height: 10%;
   /* 스크롤바의 길이 */
   background: gainsboro;
   /* 스크롤바의 색상 */
   border-radius: 80px;
}

.listform_wrap::-webkit-scrollbar-track {
   background: rgba(243, 247, 253, 0.37);
   /*스크롤바 뒷 배경 색상*/
   /* height: 90%; */
   margin-top: 45px;
}

.listform_column_wrap {
   background: white;

}


.listform_table {
   background: #94939385;
   border-collapse: collapse;
   color: #fff;
   width: 100%;
}

.listform_table th:first-child{
   text-align: center;
}

.xi-check{
   color: greenyellow;
}


.listform_table th,
.listform_table td {
   padding: 10px;
   border-bottom: 1px solid #a19898;
   border-width: 90%;
   max-width: 200px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.listform_table th {
   position: sticky;
   top: 0;
   background: cadetblue;
   z-index: 9;
   align-items: baseline;
   color: gainsboro;
   text-align: left;
   min-width: 50px;
}

.listform_table td {
   font-size: 16px;
   color: #fff;
   /* padding-left: 20px; */
}

.listform_table th i {
   float: right;
}

.status {
   display: inline-block;
   width: 80%;
   border-radius: 5px;
   display: flex;
   align-items: center;
   justify-content: center;
}

.status>span {
   font-size: 12px;
}

.status span {
   margin: 4px;
}

.xi-trash {
   color: gainsboro;
}

.xi-pen-o {
   color: gainsboro;
}

.list_form_img{
   max-height: 50px;
   object-fit: cover;
   margin-right: 4px;
}

.list_form_input{
   max-width: 100px;
}