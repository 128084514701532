.customerserviceheader {
  font-family: 'TmoneyRoundWindExtraBold';
  font-size: 28px;
  padding: 20px 0;
  text-align: center;
  color: #333;
}

.frequentlyaskedcategory {
  font-family: 'TmoneyRoundWindExtraBold';
  display: flex;
  justify-content: space-around;
}

.frequentlyaskedcategory1,
.frequentlyaskedcategory2,
.frequentlyaskedcategory3,
.frequentlyaskedcategory4 {
  font-family: 'TmoneyRoundWindExtraBold';
  width: 160px;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  font-weight: bold;
  color: #333;
  transition: background-color 0.3s ease;
}

.askservicebtn {
  font-family: 'TmoneyRoundWindExtraBold';
  width: 100%;
  text-align: left;
  padding: 15px;
  border: none;
  border-bottom: 1px solid #ccc;
  background: #fff;
  margin-top: 20px;
  font-size: 17px;
  cursor: pointer;
}

.askservicecontent {
  font-family: 'TmoneyRoundWindExtraBold';
  padding: 15px;
  text-align: left;
  margin-left: 10px;
  background-color: #f9f9f9;
  margin-top: 10px;
  border-radius: 10px;
}

.contentsmallheader {
  font-size: 24px;
  font-weight: bold;
  color: #7de4ff;
}

.selectedCategory {
  background-color: #007bff;
  color: #fff;
}

.linktoQNA {
  text-align: right;
  margin: 30px;
  font-size: 24px;
  font-weight: bold;
  color: #fe7cf3;
  cursor: pointer;
}