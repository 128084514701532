.slot_wrap{
   position: relative;
   background-color: #000;
   width: 250px;
   height: 320px;
   margin: 0 auto;
   box-shadow: 4px 4px 3px gray;
   border-radius: 30px;
   overflow: hidden;
   opacity: 0;
   transform: translateY(100%); /* 시작 위치 설정 */
   transition: opacity 0.5s ease, transform 0.3s ease-in-out; /* 트랜지션 설정 */
}

.slot_span{
   position: absolute;
   font-size: 18px;
   bottom: 0;
   width: 100%;
   height: 95px;
   background: #000;
   opacity: 0.7;
   color: #fff;
   transform: translateY(100%); /* 시작 위치 설정 */
   transition: transform 0.3s ease-in-out; /* 트랜지션 설정 */
}

.slot_span span{
   padding: 4px 10px;
}

.slot_img{
   position: absolute;
   height: 100%;
   cursor: pointer;
}

.slot_wrap.active {
   opacity: 1;
   transform: translateY(0); /* 목표 위치 설정 */
 }

 .slot_wrap:hover {
   transform: scale(1.1); /* 호버 시 1.2배 확대 */
 }

 .slot_span.hover{
    transform: translateY(0);
 }

 .slot_wrap>a>div{
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;

 }