.mypagewhole {
  margin-right: 50px;
  margin-left: 50px;
  padding-top: 160px;
  font-family: 'Noto Sans KR', sans-serif;
}

.accountinfo {
  background-image: linear-gradient(to left, #7de4ff, #fe7cf3);
  display: flex;
  width: 100%;
  height: 150px;
  margin: 50px auto;
  align-items: center;
  border-radius: 10px;
}

.accountcontent {
  font-size: 25px;
  font-weight: bold;
  color: #fff;
  margin: 0 100px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.accountsetbtn {
  border: 0px;
  border-radius: 20px;
  position: absolute;
  right: 80px;
  background: linear-gradient(20deg, #fe7cf3, #7de4ff);
  color: white;
  padding: 9px 15px;
}

.mypageflex {
  display: flex;
}

.mypagecomponunt {
  flex: 1;
  margin-top: 50px;
  margin-left: 250px;
}

.mypageside {
  flex: 0 0 200px;
  margin-left: 50px;
  width: 400px;
}

.mypagesmallheader {
  width: 350px;
  height: 50px;
  color: #7de4ff;
  font-size: 26px;
  margin-top: 32px;
  border-bottom: 1.5px solid rgb(216, 214, 214);
  text-align: left;
  font-weight: bold;
  margin-bottom: 20px;
}

.mypageside ul {
  list-style-type: none;
  width: 200px;
}

.mypageside li button {
  font-family: 'Noto Sans KR', sans-serif;
  width: 300px;
  margin-top: 10px;
  margin-left: 30px;
  height: 70px;
  padding: 10px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
  font-size: 18px;
  background: white;
  box-shadow: 1px 1px 3px grey;
  margin-bottom: 10px;
  border-radius: 10px;
}

.mypageactive {
  color: #fe7cf3;
}

.mypageside li button:hover {
  transform: translateX(45px);
}

.mypagefooter {
  width: 100%;
  height: 300px;
  background-color: green;
  margin-top: 50px;
}

.changepassword,
.checknewpassword {
  font-family: 'TmoneyRoundWindExtraBold';
  padding-left: 0px;
  padding-bottom: 5px;
  border: 0px;
  border-bottom: 2px solid;
  border-color: gray;
  font-size: 20px;
  outline: none;
  width: 250px;
  margin-right: -30px;
}

.basicsituation {
  width: 800px;
  height: 250px;
  margin-top: 100px;
}

.situationsmallheader {
  background: #7de4ff;
  color: #fff;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  height: 50px;
  border-radius: 10px;
}

.situationstatus {
  display: flex;
}

.situationstatusdeliver1,
.situationstatusdeliver2,
.situationstatusdeliver3 {
  padding-top: 20px;
  border-right: 1.5px solid rgb(216, 214, 214);
  width: 180px;
  height: 120px;
  margin-top: 20px;
  text-align: center;
}

.situationstatusdeliver4 {
  padding-top: 20px;
  width: 180px;
  height: 120px;
  margin-top: 20px;
  text-align: center;
}

.deliveryvalue {
  padding-top: 15px;
  font-size: 45px;
  color: #fe7cf3;
}

@media (max-width: 880px) {
  .accountcontent_span1{
    display: none;
  }
}