.adminpagination_wrap{
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 12px;
}

.adminpaging_pagenum_div{
   display: flex;
   justify-content: center;
   align-items: center;
}

.adminpagenum_round{
   margin: 0 10px;
}