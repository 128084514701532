.account-header{
  color: #7de4ff;
}

.passwordchangeguide {
  margin-top: 160px;
  font-size: 16px;
  color: #fe7cf3;
}

.currentpassword {
  margin-top: 10px;
  font-size: 23px;
}
.currentpassword input{
  border: 0;
  outline: none;
  border-bottom: 1px solid;
  margin-left: 20px;
  font-size: 23px;
}

 .currentpassword button {
  border: 0;
  outline: none;
  margin-left: 20px;
  background: white;
  font-size: 23px;
}

.toggleshowpw .xi-eye, .toggleshowpw .xi-eye-off{
  font-size: 23px;
  border: 0;
  margin-left: -80px;
  color: #fe7cf3;
}

.toggleshowpw{
  border: 0;
  outline: none;
  margin-left: 20px;
  background: white;
  font-size: 23px;
}

.newpasswordreset {
  font-size: 23px;
  margin-top: 20px;
}

.newpasswordresetinput {
  border: 0;
  outline: none;
  border-bottom: 1px solid;
  margin-left: 20px;
  font-size: 23px;
}

.resetpwconfirm {
  font-family: 'Noto Sans KR', sans-serif;
  width: 300px;
  padding: 10px 10px;
  border: 0;
  outline: none;
  font-size: 23px;
  margin-top: 80px;
  border-radius: 25px;
  color: #7de4ff;
}

.pwvalid5 {
  color: #fe7cf3;
  margin-top: 20px;
  margin-left: 280px;
}