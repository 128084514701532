.slideevent_wrap {
    width: 100%;
    height: 100%;
    
    color: white;

    overflow-y: auto;
}

.event2_header {
    background: rgba(148, 147, 147, 0.53);
    color: gainsboro;
    font-size: 30px;
    font-weight: bold;
    height: 100px;
    display: flex;
    align-items: center;
}

.event2_header span{
    margin-left: 70px;
}


.event2_deleteList_wrap {
    border: 1px solid #b6b6b6;
    border-radius: 10px;
    height: auto;
    max-height: 1300px;
    /* margin: 10px; */
    padding: 10px;
    background: rgba(148, 147, 147, 0.53);
    color: white;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow: auto;
}

.event2_deliteList_content {
    width: 90%;
    max-height: 1000px;
    margin: 10px auto;
    overflow: auto;
    border-radius: 20px;
}

.event2_delete_table {
    background: #94939385;
    border-collapse: collapse;
    color: #fff;
    width: 100%;
    border-radius: 20px;
}

.event2_delete_table th,
.event2_delete_table td {
    padding: 10px;
    border-bottom: 1px solid #a19898;
    border-width: 90%;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.event2_delete_table th {
    top: 0;
    background: cadetblue;
    z-index: 9;
    align-items: baseline;
    color: gainsboro;
    /* text-align: center; */
    min-width: 50px;
}

.event2_delete_table td {
    font-size: 16px;
    color: #fff;
}

.event2_deleteList_image {
    width: 80px;
    height: 50px;
}

.event_insert_btn{
    display: inline-block;
    width: 150px;
}

.event2_insert_wrap {
    background: rgba(148, 147, 147, 0.53);
    border: 1px solid #b6b6b6;
}

.event2_input_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.event2_image_input {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}

.event2_image_input img{
    width: 450px;
    height: 600px;
}

.event2_input_wrap span{
    margin: 10px 0 0;
}

.event2_input_wrap input{
    width: 300px;
    margin: 5px 0;
}

.event2_btnAll_wrap{
    width: 100%;
    margin-top: 40px;
    display: flex;
    margin-bottom: 20px;
}

.event2_insert_btn,
.event2_reset_btn{
    display: inline-block;
    width: 150px;
}

.event2_list_wrap{
    border: 1px solid #b6b6b6;
    border-radius: 5px;
    margin: 10px 10px 10px;
    background: rgba(148, 147, 147, 0.53);
    text-align: center;
    color: white;
    height: 410px;
}

.event2_list_contents{
    height: 320px;
    overflow-y: auto;

}

.event2_list_wrap span{
    display: block;
    margin-left: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
    text-align: left;
}

.event2_list_li{
    display: flex;
    justify-content: space-between;
    height: 60px;
    margin: 4px auto;
    padding-bottom: 4px;
    border-bottom: 0.5px solid #b6b6b6;
    width: 90%;
}

.event2_list_li p{
    margin-right: 10px;
    text-overflow: ellipsis;
}

.event2_list_li input{
    margin-right: 10px;
}

.event2_btnAll_wrap2{
    display: flex;
    justify-content: right;
    margin: 10px 10px 10px 0 ;
    align-items: flex-end;
}

.event2_btnAll_wrap2 button{
    height: 20px;
    margin: 0 10px;
}