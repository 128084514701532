@font-face {
   font-family: 'TmoneyRoundWindExtraBold';
   src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/TmoneyRoundWindExtraBold.woff') format('woff');
   font-weight: normal;
   font-style: normal;
 }

.celeb_wrap{
   width: 100%;
   height: 100%;
   text-align: center;
}

.span_div{
   display: flex;
   justify-content: space-between;
   font-family: 'TmoneyRoundWindExtraBold';
   color: #7de3ff;
   font-size: 26px;
   margin: 0 180px ;
}

.celebbar{
   position: relative;
   margin: 30px auto;
   width: 80%;
   height: 180px;
   border-radius: 100px;
   box-shadow: 2px 2px 6px gray;
   overflow: hidden;
   transition: all 0.5s ease-in-out;
}

.celebbar_gradient1{
   align-items: center;
   width: 100%;
   height: 100%;
   background: linear-gradient(to right, #7de3ff, #FE7CF3);
   /* animation: rotateGradient 6s linear infinite; */
}

.celebbar_gradient2{
   position: absolute;
   align-items: center;
   display: flex;
   left: 2.5px;
   right: 2.5px;
   top: 2.5px;
   bottom : 2.5px;
   background-color: #fff;
   border-radius: 100px;
   overflow: hidden;
}

.celebslide{
   display: inline-block;
}

.itemLi_btn{
   border: 0px;
   width: 100%;
   height: 100%;
   background: transparent;
   outline: none;
}

.celeb_morebtn{
   cursor: pointer;
}

.celebbar.moreActive{
   height: 520px;
}

.celebbar_gradient2.moreActive{
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

@keyframes rotateGradient {
   0% {
     background-position: 0% 50%;
   }
   100% {
     background-position: 100% 50%;
   }
 }
 